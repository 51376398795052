<template>
  <Component :is="tag" ref="el">
    <span v-for="(line, lineIndex) in splitText" :key="lineIndex">
      <span
        v-for="(character, characterIndex) in line"
        :key="lineIndex + '' + characterIndex"
        ref="charRefs"
        class="text-animation-character-appear-from"
        >{{ character }}</span
      >
      <br />
    </span>
  </Component>
</template>

<script setup lang="ts">
import { useScrollTrigger } from '#gsap'

const props = defineProps({
  tag: {
    type: String,
    required: true,
  },
  text: {
    type: String,
    required: true,
  },
  duration: {
    type: Number,
    default: 750,
  },
})

const el = ref<HTMLElement | null>(null)
const charRefs = ref<HTMLElement[]>([])
let ctx: any = null

// function to generate random number from 1 to 10
const random = (n: number) => Math.floor(Math.random() * n) + 1

const splitText = computed(() => {
  if (!props.text) return []
  const lines = props.text.split('\n')

  return lines.map((line) => line.split(''))
})

onMounted(() => {
  initGsap()
})

onUnmounted(() => {
  ctx.revert()
})

const initGsap = () => {
  ctx = useGsap.context(() => {
    useScrollTrigger.create({
      trigger: el.value,
      onEnter: () => animateCharacters(),
      // onLeave: () => resetCharacters(),
      // onEnterBack: () => animateCharacters(),
      // onLeaveBack: () => resetCharacters(),
    })
  })
}

const animateCharacters = () => {
  charRefs.value.forEach((charRef) => {
    charRef.style.animationDelay = `${random(10) * (props.duration / 10)}ms`
    charRef.classList.add('animate-character-appear')
  })
}

// const resetCharacters = () => {
//   charRefs.value.forEach((charRef) => {
//     charRef.style.animationDelay = '0ms'
//     charRef.classList.remove('animate-character-appear')
//   })
// }
</script>
